<template>
  <div>
    <b-modal
      ref="modalDataCampaign"
      :title="$t('Escolha uma campanha')"
      :cancel-title="$t('Fechar')"
      cancel-variant="outline-secondary"
      size="lg"
      body-class=""
      :visible="false"
      :ok-only="false"
      :ok-title="$t('Guardar')"
      ok-variant="primary"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      @ok="confirmSaveModal"
      @cancel="hideModal"
    >

      <validation-observer
        ref="formChooseCampaign"
        #default="{}"
      >
        <b-row>
          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              rules="required"
            >
              <b-form-group
                :label="$t('Campanhas')"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <v-select
                  v-model="campanha"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="getListCampanhas"
                  :value="campanha"
                  append-to-body
                  :calculate-position="withPopper"
                  :loading="loadingCampanhas"
                  label="nomeCamp"
                  item-text="nomeCamp"
                  item-value="id"
                  :filterable="false"
                  @search="searchCampanhas"
                  @input="optionsByCampanha"
                >
                  <template #option="{ nomeCamp, membro }">
                    {{ nomeCamp }}
                    <template v-if="membro">
                      <small>{{ membro }}</small>
                    </template>
                  </template>
                  <div slot="no-options">
                    {{ $t('Sem resultados') }}
                  </div>

                  <div
                    v-if="totalCampanhas > limit"
                    slot="list-footer"
                    class="pagination"
                  >
                    <b-button-group
                      class="mt-1 d-flex w-100"
                    >
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        class="btn-icon rounded-top-0"
                        variant="outline-secondary"
                        :disabled="offset===0"
                        @click="offset -=limit; hasPrevPage()"
                      >
                        <feather-icon icon="ChevronLeftIcon" />
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        class="btn-icon rounded-top-0"
                        variant="outline-secondary"
                        :disabled="(offset+limit+limit) >= totalCampanhas"
                        @click="offset +=limit; hasNextPage()"
                      >
                        <feather-icon icon="ChevronRightIcon" />
                      </b-button>
                    </b-button-group>
                  </div>
                </v-select>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row
          v-if="showWeek===true"
        >
          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              rules="required"
            >
              <b-form-group
                :label="$t('Semana de início')"
                :description="semana_txt"
              >
                <date-picker
                  ref="datepickerWeekend"
                  v-model="semana"
                  value-type="format"
                  :input-class="{'form-control' : true, 'is-invalid': errors.length > 0 }"
                  type="week"
                  :editable="false"
                  :clearable="true"
                  :lang="langCalendar"
                  :append-to-body="true"
                  :show-week-number="true"
                  :format="formatCalendarWeek"
                  prefix-class="sw"
                  :disabled-date="disabledBeforeToday"
                  @input="chooseWeekCalendar"
                >
                  <template #icon-clear>
                    <v-select-deselect />
                  </template>
                </date-picker>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row
          v-if="showMonth===true"
        >
          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              rules="required"
            >
              <b-form-group
                :label="$t('Mês de início')"
              >
                <date-picker
                  v-model="mes"
                  value-type="format"
                  :input-class="{'form-control' : true, 'is-invalid': errors.length > 0 }"
                  type="month"
                  :editable="false"
                  :clearable="true"
                  :lang="langCalendar"
                  :append-to-body="true"
                  :disabled-date="disabledBeforeToday"
                  prefix-class="sw"
                >
                  <template #icon-clear>
                    <v-select-deselect />
                  </template>
                </date-picker>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

      </validation-observer>

    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BRow, BCol, BFormGroup, BButton, BButtonGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'
import useAppConfig from '@core/app-config/useAppConfig'
import { format, getWeek } from 'date-fns'
import btoa from 'btoa'
import { mixFormGeral } from '../../mixins'

const oDatepicker = () => Promise.all([
  import(/* webpackChunkName: "chunk-vue2-datepicker" */ 'vue2-datepicker'),
  import(/* webpackChunkName: "chunk-vue2-datepicker" */ '@/assets/scss/datepicker.scss'),
]).then(([DatePicker]) => DatePicker)

export default {
  components: {
    BModal,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BButtonGroup,
    vSelect,
    DatePicker: oDatepicker,
    vSelectDeselect: vSelect.components.Deselect,
  },
  directives: {
    Ripple,
  },
  mixins: [mixFormGeral],
  props: {
    data: {
      type: Array,
      required: true,
    },
    total: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      campanha: null,
      semana: null,
      semana_txt: null,
      semanaInicio: null,
      semanaFim: null,
      mes: null,
      offset: 0,
      limit: 50,
      loadingCampanhas: false,
      searchTxt: '',
      resolvePromise: null,
      rejectPromise: null,
      langCalendar: {},
      formatCalendarWeek: `[${this.$t('Semana')}] w [${this.$t('de')}] YYYY`,
      showWeek: false,
      showMonth: false,
    }
  },
  computed: {
    ...mapActions('crm_leads_form', ['getCampanhas', 'setAssocCampanhaLead']),
    getListCampanhas() {
      return this.listCampanhas
    },
    isDark() {
      return this.skin.value === 'dark'
    },
  },
  async mounted() {
    await this.importLocale(this.$i18n.locale).then(res => {
      this.langCalendar = res.default
      // Forçei estes valores para a semana começar sempre no domingo
      this.langCalendar.formatLocale.firstDayOfWeek = 0
      this.langCalendar.formatLocale.firstWeekContainsDate = 4
    })
  },
  methods: {
    showModal() {
      return new Promise((resolve, reject) => {
        this.$refs.modalDataCampaign.show()
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    async hideModal() {
      this.resolvePromise(true)
      this.$refs.modalDataCampaign.hide()
    },
    hasNextPage() {
      const nextOffset = this.offset + this.limit

      this.loadingCampanhas = true
      this.$store.dispatch('crm_leads_form/getCampanhas', { start: nextOffset, search: this.searchTxt }).then(result => {
        this.listCampanhas = result.campanhas || []
        this.loadingCampanhas = false
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    hasPrevPage() {
      let prevOffset = this.offset - this.limit
      if (prevOffset < 0) {
        prevOffset = 0
      }

      this.loadingCampanhas = true
      this.$store.dispatch('crm_leads_form/getCampanhas', { start: prevOffset, search: this.searchTxt }).then(result => {
        this.listCampanhas = result.campanhas || []
        this.loadingCampanhas = false
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    searchCampanhas(txt) {
      this.searchTxt = txt
      this.loadingCampanhas = true
      this.$store.dispatch('crm_leads_form/getCampanhas', { start: 0, search: this.searchTxt }).then(result => {
        this.listCampanhas = result.campanhas || []
        this.totalCampanhas = result.total || 0
        this.loadingCampanhas = false
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    disabledBeforeToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      return date < today
    },
    chooseWeekCalendar(date) {
      if (date) {
        const txtSemana = this.$t('Semana')
        const txtDe = this.$t('de')
        const sDateTxtSel = date.replaceAll(txtSemana, '')
        const aDateTxtSel = sDateTxtSel.split(txtDe)
        this.semana_txt = ''
        this.semanaInicio = ''
        this.semanaFim = ''

        try {
          if ((0 in aDateTxtSel) && (1 in aDateTxtSel)) {
            const oDatesInterval = this.getDateRangeOfWeek(aDateTxtSel[1].trim(), aDateTxtSel[0].trim())
            this.semana_txt += `${aDateTxtSel[0].trim()} > `

            if ('from' in oDatesInterval) {
              this.semana_txt += oDatesInterval.from
              this.semanaInicio = oDatesInterval.from
            }

            if ('to' in oDatesInterval) {
              this.semana_txt += ' / '
              this.semana_txt += oDatesInterval.to
              this.semanaFim = oDatesInterval.to
            }
          }
        } catch (err) {
          //
        }
      }
    },
    getDateRangeOfWeek(y, weekNo) {
      const d1 = new Date()
      d1.setYear(y)
      const numOfdaysPastSinceLastMonday = d1.getDay()
      const weekNoToday = getWeek(d1, {
        weekStartsOn: this.langCalendar.formatLocale.firstDayOfWeek,
        firstWeekContainsDate: this.langCalendar.formatLocale.firstWeekContainsDate,
      })
      const weeksInTheFuture = (weekNo - weekNoToday)

      d1.setDate(d1.getDate() - numOfdaysPastSinceLastMonday)
      d1.setDate(d1.getDate() + (7 * weeksInTheFuture))

      const rangeIsFrom = format(d1, 'yyyy-MM-dd')
      d1.setDate(d1.getDate() + 6)
      const rangeIsTo = format(d1, 'yyyy-MM-dd')

      return {
        from: rangeIsFrom,
        to: rangeIsTo,
      }
    },
    async importLocale(lang) {
      return import(`vue2-datepicker/locale/${lang}`)
    },
    optionsByCampanha(row) {
      if ((row !== null) && (row !== undefined)) {
        if ('tipo' in row) {
          if (this.checkIsWeek(row) === true) {
            this.blockFormWeek(true)
            this.blockFormMonth(false)
          } else if (this.checkIsMonth(row) === true) {
            this.blockFormWeek(false)
            this.blockFormMonth(true)
          } else {
            this.blockFormWeek(false)
            this.blockFormMonth(false)
          }
        }
      }
    },
    checkIsWeek(row) {
      if (Number(row.tipo) === 1 || Number(row.tipo) === 2 || Number(row.tipo) === 6 || Number(row.rotina) === 1) {
        return true
      }

      return false
    },
    checkIsMonth(row) {
      if (Number(row.tipo) === 4 || Number(row.rotina) === 2) {
        return true
      }

      return false
    },
    blockFormMonth(visble) {
      this.mes = null
      if (visble === true) {
        this.showMonth = true
      } else {
        this.showMonth = false
      }
    },
    blockFormWeek(visble) {
      this.semana = null
      this.semana_txt = null
      this.semanaInicio = null
      this.semanaFim = null

      if (visble === true) {
        this.showWeek = true
      } else {
        this.showWeek = false
      }
    },
    async confirmSaveModal(evt) {
      evt.preventDefault()
      await this.$refs.formChooseCampaign.validate()
        .then(async valid => {
          if (valid === true) {
            const oCampanha = {}

            if ((this.campanha !== null) && (this.campanha !== undefined) && ('id' in this.campanha)) {
              oCampanha.id = btoa(this.campanha.id)

              if ((this.checkIsWeek(this.campanha) === true) && (this.semanaInicio)) {
                oCampanha.semana = this.semanaInicio
              }

              if ((this.checkIsMonth(this.campanha) === true) && (this.mes)) {
                oCampanha.mes = this.mes
              }
            }

            this.$store.dispatch('crm_leads_form/setAssocCampanhaLead', oCampanha)

            this.resolvePromise(true)
          } else if (valid === false) {
            if ((this.campanha !== null) && (this.campanha !== undefined) && ('id' in this.campanha)) {
              if ((this.checkIsWeek(this.campanha) === true) && (!this.semanaInicio)) {
                this.showMsgErrorRequest({ message: this.$t('Semana de início não preenchida') })
              }

              if ((this.checkIsMonth(this.campanha) === true) && (!this.mes)) {
                this.showMsgErrorRequest({ message: this.$t('Mês de início não preenchido') })
              }
            } else {
              this.showMsgErrorRequest({ message: this.$t('Nenhuma campanha selecionada') })
            }
          }
        })
    },
  },
  setup(props) {
    const { skin } = useAppConfig()

    return {
      listCampanhas: props.data,
      totalCampanhas: props.total,
      skin,
    }
  },
}
</script>
